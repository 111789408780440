import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Lottie from "react-lottie";
import { useMediaQuery } from "react-responsive";
import { isMobile } from "react-device-detect";
import header from "../animations/header.json";

import { BLUE, CREAM } from "../config/colors";
import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRoll";
import logo from "../img/logo.png";
import aboutMeAnita from "../img/about-me-anita.jpeg";

export const IndexPageTemplate = ({
	image,
	title,
	heading,
	headerOptions,
	subheading,
	mainpitch,
	description,
	intro,
}) => {
	const [animationStop, setAnimationStop] = useState(false);
	const [focusSecret, setFocusSecret] = useState(false);

	const getWindowDimensions = () => {
		if (typeof window === "undefined") {
			return { width: isMobile ? 600 : 1100, height: isMobile ? 600 : 1100 };
		}
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height,
		};
	};

	const useWindowDimensions = () => {
		const [windowDimensions, setWindowDimensions] = useState(
			getWindowDimensions()
		);

		useEffect(() => {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}, []);

		return windowDimensions;
	};

	const { width: screenWidth } = useWindowDimensions();

	const isHeaderFlat = screenWidth > 1050;
	const isTablet = useMediaQuery({
		query: "(min-width: 693px) and (max-width: 1050px)",
	});
	const isVerySmol = useMediaQuery({ query: "(max-width: 285px)" });

	const defaultHeaderOptions = {
		loop: false,
		autoplay: true,
		animationData: header,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	const playAnimation = async () => {
		await setFocusSecret(true);
		setAnimationStop(true);
		await setAnimationStop(false);
	};

	const onSecretFocus = () => {
		setFocusSecret(true);
	};
	const onSecretBlur = () => {
		setFocusSecret(false);
	};

	return (
		<div>
			<div
				className="margin-top-0"
				style={{
					display: "flex",
					backgroundColor: CREAM,
					minHeight: "400px",
					justifyContent: "center",
				}}
			>
				{/** Header animation */}
				<div
					className="columns margin-top-0"
					style={{
						position: "absolute",
						width: "100%",
						height: "450px",
						marginBottom: "0px",
					}}
				>
					<div className="column is-1" style={{ height: "140px" }}></div>
					<div
						className="column is-10"
						style={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<div
							style={{
								width: "100%",
								position: "relative",
								maxWidth: "1300px",
							}}
						>
							<div
								role="img"
								alt="A stroke of life animation"
								style={{
									position: "absolute",
									right: "0px",
									height: "400px",
									marginTop: isTablet
										? isHeaderFlat
											? "40px"
											: "85px"
										: "0px",
								}}
							>
								<Lottie
									options={defaultHeaderOptions}
									height={400}
									width={isHeaderFlat ? 1300 : 800}
									isStopped={animationStop}
									isPaused={false}
								/>
							</div>
						</div>
					</div>
					<div className="column is-1"></div>
				</div>

				{/** Header text */}
				<div
					className="columns margin-top-0"
					style={{
						justifyContent: "start",
						width: "100%",
						alignItems: "center",
						marginBottom: "0px",
					}}
				>
					<div className="column is-2" style={{ height: "20px" }}></div>
					<div
						className={`column ${isHeaderFlat ? "is-4" : "is-5"}`}
						style={{
							display: "flex",
							minWidth: isHeaderFlat ? "410px" : "0px",
							lineHeight: "1",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
						}}
					>
						<img
							src={logo}
							alt="A stroke of life logo"
							style={{ marginBottom: "20px" }}
						/>
						<h3
							className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
							style={{
								color: BLUE,
								lineHeight: "1",
								padding: "0.25em",
								opacity: "80%",
								textAlign: "center",
							}}
						>
							{subheading}
						</h3>
					</div>
					<div
						className={`column ${isHeaderFlat ? "is-6" : "is-3"}`}
						style={{ height: "310px" }}
					></div>
				</div>
				{/** Header animation left cream block */}
				<div
					className="columns is-gapless margin-top-0"
					style={{
						position: "absolute",
						width: "100%",
						height: "450px",
						marginBottom: "0px",
					}}
				>
					<div
						className="column is-1"
						style={{
							position: "relative",
							height: "450px",
							width: "1px",
						}}
					>
						<div
							style={{
								position: "absolute",
								left: "0px",
								bottom: "0px",
								height: "100px",
								width: "100%",
								backgroundColor: isVerySmol ? "transparent" : CREAM,
							}}
						></div>
					</div>
					<div
						className="column is-1"
						style={{
							position: "relative",
							height: "450px",
						}}
					>
						<div
							style={{
								position: "absolute",
								left: "0px",
								bottom: "0px",
								height: "120px",
								width: "100%",
								backgroundColor: isVerySmol ? "transparent" : CREAM,
							}}
						></div>
						<div
							style={{
								position: "absolute",
								left: "0px",
								top: "-20px",
								height: "100px",
								width: "100%",
								maxWidth: "12px",
								backgroundColor: isVerySmol ? "transparent" : CREAM,
							}}
						></div>
					</div>
					<div className="column is-10"></div>
				</div>
				{/** Secret button */}
				<div
					className="columns margin-top-0"
					style={{
						position: "absolute",
						width: "100%",
						height: "450px",
						marginBottom: "0px",
					}}
				>
					<div className="column is-1" style={{ height: "140px" }}></div>
					<div
						className="column is-10"
						style={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<div
							style={{
								width: "100%",
								position: "relative",
								maxWidth: "1300px",
							}}
						>
							<div
								style={{
									position: "absolute",
									right: "0px",
									height: "400px",
									width: isHeaderFlat ? "1300px" : "800px",
									marginTop: "40px",
								}}
							>
								<button
									aria-label="Secret button"
									title="Secret button"
									onClick={playAnimation}
									onMouseOver={onSecretFocus}
									onFocus={onSecretFocus}
									onMouseOut={onSecretBlur}
									onBlur={onSecretBlur}
									style={{
										position: "absolute",
										right: isHeaderFlat ? "200px" : "121px",
										top: isTablet
											? isHeaderFlat
												? "125px"
												: "199px"
											: "115px",
										height: isHeaderFlat ? "70px" : "44px",
										width: isHeaderFlat ? "64px" : "44px",
										cursor: "pointer",
										borderWidth: "0px",
										backgroundColor: "transparent",
										outline: focusSecret
											? "5px auto -webkit-focus-ring-color"
											: "none",
									}}
								/>
							</div>
						</div>
					</div>
					<div className="column is-1"></div>
				</div>
			</div>
			<section
				className="section section--gradient"
				style={{ backgroundColor: CREAM }}
			>
				<div className="container">
					<div className="section">
						<div className="columns">
							<div className="column is-10 is-offset-1">
								<div className="content">
									<div className="columns" style={{ marginBottom: 60 }}>
										<div className="column is-6 ">
											<img src={aboutMeAnita} alt="Anita Hsieh" />
										</div>
										<div className="column is-5 is-offset-1">
											<div className="content" style={{ marginBottom: 100 }}>
												<div className="tile">
													<h1 className="title" style={{ color: BLUE }}>
														{mainpitch.title}
													</h1>
												</div>
												<br></br>
												<br></br>
												<div className="tile">
													<div className="subtitle" style={{ color: BLUE }}>
														{mainpitch.description.split("\\n")[0]}
													</div>
												</div>
												<br></br>
												<br></br>
												<div className="tile">
													<div
														className="subtitle"
														style={{ color: BLUE, marginBottom: 50 }}
													>
														{mainpitch.description.split("\\n")[1]}
													</div>
												</div>
												<br></br>
												<div className="column is-12 has-text-centered">
													<Link className="btn" to="/about">
														Read more
													</Link>
												</div>
											</div>
										</div>
									</div>
									<div className="column is-12">
										<h3
											className="has-text-weight-semibold is-size-2"
											style={{ color: BLUE }}
										>
											Recent posts
										</h3>
										<BlogRoll />
										<div className="column is-12 has-text-centered">
											<Link className="btn" to="/blog">
												See more
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

IndexPageTemplate.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	heading: PropTypes.string,
	subheading: PropTypes.string,
	mainpitch: PropTypes.object,
	description: PropTypes.string,
	intro: PropTypes.shape({
		blurbs: PropTypes.array,
	}),
};

const IndexPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout>
			<IndexPageTemplate
				image={frontmatter.image}
				title={frontmatter.title}
				heading={frontmatter.heading}
				subheading={frontmatter.subheading}
				mainpitch={frontmatter.mainpitch}
				description={frontmatter.description}
				intro={frontmatter.intro}
			/>
		</Layout>
	);
};

IndexPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default IndexPage;

export const pageQuery = graphql`
	query IndexPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			frontmatter {
				title
				image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
				heading
				subheading
				mainpitch {
					title
					description
				}
				description
				intro {
					blurbs {
						image {
							childImageSharp {
								fluid(maxWidth: 240, quality: 64) {
									...GatsbyImageSharpFluid
								}
							}
						}
						text
					}
					heading
					description
				}
			}
		}
	}
`;
